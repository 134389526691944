<template>
  <v-chip
    :color="getStatus.color"
    outlined
    small
  >
    {{ getStatus.text }}
  </v-chip>
</template>

<script>
export default {
  props: {
    role: {
      type: String,
      default: 'user',
    },
  },
  data() {
    return {
      statusList: [
        {
          statusName: null,
          text: 'Pas de status',
          color: '#26C6DA',
        },
        {
          statusName: 'admin',
          text: 'Administrateur',
          color: '#FB8C00',
        },
        {
          statusName: 'tuteur',
          text: 'Tuteur',
          color: '#5C6BC0',
        },
        {
          statusName: 'user',
          text: 'Utilisateur',
          color: 'success',
        },
      ],
    }
  },
  computed: {
    getStatus() {
      return this.statusList.find(x => x.statusName === this.role)
    },
  },
}
</script>

<style>

</style>
